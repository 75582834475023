@import "../../scss/variables";
.login-form.container {
    margin-top: 80px;
    margin-bottom: 40px;
    .form-box {
        height: fit-content;
    }
    .card-header {
        padding: 0.75rem 1.25rem;
        margin-bottom: 0;
        color: #fff;
        background-color: $semi-secondary;
        border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    label {
        padding-top: calc(0.375rem + 1px);
        padding-bottom: calc(0.375rem + 1px);
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.6;
    }
    input-text {
        display: block;
        width: 100%;
        height: calc(1.6em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: 1.6;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .form-control {
        border-radius: .5rem !important;
    }
    .input:focus {
        color: #495057;
        background-color: #fff;
        border-color: #fbd1bc;
        outline: 0;
        box-shadow: 0 0 0 0.2rem #fbd1bc;
    }
    button {
        color: #fff;
        background-color: $primary;
        border-color: $primary;
        border-radius: .5rem;
    }
    button:hover {
        cursor: pointer;
        background-color:$primary;
    }
    .login-link {
        color: $primary;
        background: transparent;
        border: none;
    }
    .login-link:hover {
        background: transparent;
    }
    .login-link:focus {
        box-shadow: none;
    }
    .login-link:focus-visible {
        outline: none;
    }
}