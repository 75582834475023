@import "variables";

.container-fluid {
    .sidebar-col {
        padding-top: 80px;
        padding-bottom: 40px;
        min-height: calc(100vh - 175px);
        background: $tertiary;
        transition: left 350ms ease-in-out;
        a, .link {
            cursor: pointer;
            padding: 18px 18px 18px 32px;
            text-decoration: none;
            font-size: 20px;
            color: #474b4c;
            display: block;
            font-weight: 700;
            transition: 0.3s;
            border: none;
            background: transparent;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            width: 100%;
            text-align: left;
        }
        a:hover, .link:hover, .active {
            color: $primary !important;
            text-decoration: none;
        }
        .inner-menu {
          padding-top: 10px;
            a{
                border-bottom: none;
            }
        }
    }
    .main-col {
        background: url('./img/sto-pattern.png');
        padding: 80px 0 40px 0;
        .card {
            .card-header {
                color: #fff;
                background-color: $semi-secondary;
                .plus-button {
                    color: #fff;
                    background-color: $primary;
                    border-color: $primary;
                    border-radius: 10px;
                    margin-right: 10px;
                }
            }
            .card-body {
                overflow-x: auto;
                color: #474b4c !important;
                tr:hover:nth-child(even) {
                    color: #474b4c;
                    background-color: #eaf1f2;
                }
                th {
                    font-size: 17px;
                }
                th:nth-child(n) {
                    text-align: center;
                }
                th:first-child {
                    text-align: start;
                }
                th:last-child {
                    text-align: end;
                }
                td {
                    .secondaryButton {
                        background-color: $secondary;
                        color: #fff !important;
                    }
                    .btn {
                        border-radius: 10px;
                        font-size: 15px;
                    }
                }
                td:nth-child(n) {
                    text-align: center;
                }
                td:first-child {
                    text-align: start;
                }
                td:last-child {
                    text-align: end;
                }
                .cardPagination {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
