@import "../scss/variables";

.teacher-dashboard {
    background: url('./img/sto-pattern.png');
    .container {
        .btn {
            &.all-photos {
                background: $primary;
                color: white;
                border-radius: 2rem;
                border-radius: .5rem;
            }
        }
        .new-button {
            cursor: pointer;
        }
        .create-lesson {
            min-height: calc(100vh - 475px);
            .modal-title {
                font-size: 60px;
                font-family: Authority;
            }
        }
        &.create-portfolio {
            min-height: calc(100vh - 475px);
            .modal-title {
                font-size: 60px;
                font-family: Authority;
            }
        }
        input[type=text] {
            height: 42px;
            border-radius: 5px;
            padding-left: 10px;
        }
        .navbar {
            background-color: transparent !important;
        }
        .main-col {
            min-height: calc(100vh - 474px);
            .lessons-heading, .modereren-heading {
                font-size: 60px;
                font-family: Authority;
            }
            .svg-inline--fa.fa-w-16 {
                width: 0.85em;
            }
            .lessons {
                float: right;
                background-position: center;
                background-size: cover;
                border-radius: 10px;
                box-shadow: 0 0.5rem 1rem #00000047;
                &.grid {
                  .lessons-image {
                    height: 11rem;
                  }
                }
                &.list {
                  .lessons-image {
                    height: 100%;
                  }
                }
                .lessons-image {
                    border-radius: 5px 5px 0px 0px;
                    background-size: cover;
                    .btn-status {
                        background-color: #008000a1;
                        color: white;
                        &.open {
                            background-color: #ef3a3aeb;
                        }
                        &.pending {
                            background-color: $primary;
                        }
                    }
                }
                .lesson-card {
                    background: white;
                    border-radius: 0px 0px 5px 5px;
                    .btn-status {
                        background-color: #008000a1;
                        color: white;
                        &.open {
                            background-color: #ef3a3aeb;
                        }
                        &.pending {
                            background-color: $primary;
                        }
                    }
                    p {
                        &.lesson-name {
                            font-size: 24px;
                            font-weight: bold;
                        }
                        &.pupil-name {
                            font-size: 18px;
                            font-weight: 600;
                        }
                        &.grade {
                            font-size: 24px;
                        }
                        font-size: 18px;
                    }
                }
            }
            .noLessonFound {
                font-size: 24px;
                font-weight: 600;
            }
        }

        .row {
            .menu-heading {
                font-size: 60px;
                font-family: Authority;
            }
            .modereren-card {
                box-shadow: 0 0.5rem 1rem #00000047;
                padding: 10px;
                background: #fff;
                border-radius: 5px;
            }
            .ellipsis-style {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .card {
                height: fit-content;
                background: rgb(247, 203, 183);
                box-shadow: 0 0.5rem 1rem #00000047;
                &.filter {
                    background: white;
                    background-position: center;
                    background-size: cover;
                    border-radius: 10px;
                    box-shadow: 0 0.5rem 1rem #00000047;
                }
                &.accordion {
                    background: white;
                    background-position: center;
                    background-size: cover;
                    border-radius: 0px;
                    box-shadow: none;
                    border: 0px;
                    .card-header {
                        background: white;
                    }
                }
                .btn {
                    background: $primary;
                    color: white;
                    border-radius: 2rem;
                    &.search-btn {
                        border-radius: .5rem;
                        //font-size: 30px;
                    }
                }
                .card-body {
                    .card-title {
                        font-size: 24px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.lesson-loader {
    height: 500px;

    .spinner-component {
        left : 0;
    }
}
