@import "variables";
@import "login-form";
@import "~bootstrap/scss/bootstrap";
@import "dashboard";
@import "teacher-dashboard";
@import "pupil-dashboard";
@import "font-css.scss";
* {
    font-family: "Heebo", sans-serif;
    font-size: 1.1rem;
}

.color-grayish {
    color: #505050 !important
}

.container-fluid.padding-80 {
    padding: 0px 80px;
}

.sto-navbar li {
    position: relative;
}

.navbar-light .navbar-nav .nav-link {
    color: $primary;
}

.sto-navbar li a {
    font-size: 22px;
    font-weight: bold;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.sto-navbar li .dropdown-toggle {
    font-size: 22px;
    font-weight: bold;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

a {
    color: $primary;
    text-decoration: none;
    background-color: transparent;
}

.sto-navbar li:not(:first-child):after {
    content: "";
    height: 50%;
    width: 2px;
    position: absolute;
    top: 25%;
    background-color: $primary;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: $secondary;
}

.sto-navbar li .dropdown-toggle:focus {
    color: $primary;
}

.padding-80 {
    padding: 0px 80px;
}

.btn {
    &.btn-primary {
        color: white;
    }
    &.btn-secondary {
        color: white;
    }
}

.card_view {
    border: 1px solid #ccc;
    padding: 31px;
    border-radius: 23px;
    box-shadow: -1px -1px 9px 3px rgba(0, 0, 0, .07);
    &.bradius-10 {
        border-radius: 10px;
    }
    .add_details {
        font-size: 16px;
    }
    svg path {
        fill: $primary;
    }
}

.main-container {
    height: 100%;
    .card {
        min-height: 100%;
        .card-body.overflow-visible {
            overflow: visible;
        }
        .panel-content {
            background: pink;
        }
    }
}

// Loader
.spinner-component {
    position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        top: 40%;
        margin: 0 auto;
        position: relative;
        width: 80px;
        height: 80px;
        div {
            transform-origin: 40px 40px;
            animation: spinner 1.2s linear infinite;
            &:after {
                content: " ";
                display: block;
                position: absolute;
                top: 3px;
                left: 37px;
                width: 6px;
                height: 18px;
                border-radius: 20%;
                background: red;
            }
            &:nth-child(1) {
                transform: rotate(0deg);
                animation-delay: -1.1s;
            }
            &:nth-child(2) {
                transform: rotate(30deg);
                animation-delay: -1s;
            }
            &:nth-child(3) {
                transform: rotate(60deg);
                animation-delay: -0.9s;
            }
            &:nth-child(4) {
                transform: rotate(90deg);
                animation-delay: -0.8s;
            }
            &:nth-child(5) {
                transform: rotate(120deg);
                animation-delay: -0.7s;
            }
            &:nth-child(6) {
                transform: rotate(150deg);
                animation-delay: -0.6s;
            }
            &:nth-child(7) {
                transform: rotate(180deg);
                animation-delay: -0.5s;
            }
            &:nth-child(8) {
                transform: rotate(210deg);
                animation-delay: -0.4s;
            }
            &:nth-child(9) {
                transform: rotate(240deg);
                animation-delay: -0.3s;
            }
            &:nth-child(10) {
                transform: rotate(270deg);
                animation-delay: -0.2s;
            }
            &:nth-child(11) {
                transform: rotate(300deg);
                animation-delay: -0.1s;
            }
            &:nth-child(12) {
                transform: rotate(330deg);
                animation-delay: 0s;
            }
        }
    }
}

@keyframes spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.Toastify {
    .Toastify__toast--success {
        color: green;
    }
    .Toastify__toast--error {
        color: red;
    }
}

//react-bootstrap
.add-lesson-popup {
    .modal-header {
        span {
            font-size: 28px;
        }
    }
}

.modal-header .close {
    margin: -1rem -1rem -1rem -1rem;
}

.indexedStep {
    color: white;
    width: 20px;
    height: 20px;
    font-size: 12px;
    background-color: rgba(211, 211, 211, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indexedStep.accomplished {
    background-color: #f15c14;
}

.RSPBprogressBar .RSPBprogression {
    background-color: #ee7e46 !important;
}

/* It will remove arrow from number type input */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
input.background-white {
    background: white!important;
}

.class-detail-pupil {
    position: relative;
}
.class-detail-pupil ul{
    position: absolute;
}
.class-detail-pupil #navbarDropdown {
    padding: 0;
}
.class-detail-pupil .dropdown-toggle::after {
    content: none;
}

.main-col {
    padding-bottom: 100px;
}

.searchbox input {
    border: 1px solid #dee2e6;
    padding-left: 15px ;
}


.container {
    position: relative;
}
.lesson-image {
    position: absolute;
    right: 0;
}

.text-underline{
    text-decoration: underline;
}

.lesson-description{
    font-size: 25px;
    font-weight: 600;
}

@media only screen and (max-width: 767px) {
    .lesson-image {
        position: relative;
    }
}

.lesson-ribbon {
  position: absolute;
  top: 0px;
  right: 0;
  font-weight: 700;

  .status-label {
    padding: 5px 10px;
    font-size: 16px;
    background-color: gray;
    color: white;

    &.pending {
      background-color: #ef7236;
    }

    &.open {
      background-color: #ef3a3a;
    }

    &.done {
        background-color: #1D8000;
      }

    &.no-grade {
      border-bottom-left-radius: $border-radius;
    }

    border-top-right-radius: $border-radius;
  }

  .grade-label {
    width: 100%;
    text-align: center;
    padding: 2px 10px;
    font-size: 22px;

    background-color: $secondary;
    color: white;
    border-bottom-left-radius: $border-radius;
  }
}

.menu-button{
    font-size: 24px; 
     color: white;
     background: $primary;
     border-radius: .4rem;
}

.primary{
    color:$primary;
}

.green{
    color:#47a600;
}

