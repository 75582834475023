@import "variables";
.container-fluid.footer {
    background-position: center;
    background-size: cover;
    .padding-25 {
        position: relative;
        padding-top: 25px;
        padding-bottom: 25px;
        &.first {
            background: $secondary;
            padding-left: 80px;
        }
        &.second {
            background: $semi-secondary;
        }
        &.third {
            background: $tertiary;
            padding-right: 80px;
        }
        h4 {
            font-weight: bold;
            color: $primary;
        }
    }
}