@import "../../scss/variables";
.sto-navbar {
    min-height: 170px;
    .logo-link {
        z-index: 99;
        margin-bottom: -90px;
        .logo {
            width: 200px;
        }
    }
}

nav.bg-light {
    background-color: #fff !important;
}

nav .form-control.headerSearchInput {
    display: none;
}

nav {
    ul {
        li {
            .nav-link.showInputBox:hover~.form-control.headerSearchInput {
                display: block;
            }
        }
    }
}

.profile-card {
    margin-left: -50px;
    margin-top: -50px;
    padding: 12px 50px;
    min-width: 220px;
    background: $secondary;
    color: white;
    clip-path: polygon(0 16%, 10% 0, 90% 0, 100% 16%, 100% 84%, 90% 100%, 10% 100%, 0% 84%, 0% 10%);
}
.profile-card .username {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}
.profile-card .classname {
    font-size: 16px;
    margin: 0;
}

